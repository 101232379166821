<template>
  <layout :loading="loading">
    <template v-slot:content>
      <div class="container mt-4">
        <div class="flex">
          <router-link to="/main" class="mx-4 text-blue-500">
            <i class="fas fa-chevron-left font-semibold"></i>
            {{ $t('back') }}
          </router-link>
        </div>
        <form class="m-4 md:mx-24" @submit="onSubmit">
          <div class="flex mb-4">
            <label class="w-32 font-semibold">{{ $t('edit_special_jobs') }}</label>
            <t-textarea v-model="body" class="flex-grow" rows="5"></t-textarea>
          </div>
          <div class="ml-28">
            <t-button class="flex-grow" variant="primary" :disabled="loading">{{ $t('edit_save') }}</t-button>
          </div>
        </form>
      </div>
    </template>
  </layout>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { getters, actions } from "@/constants/state";
import * as _ from "lodash";
import Layout from "@/components/Layout.vue";

export default {
  components: { Layout },
  data: () => ({
    loading: false,
    body: null
  }),
  mounted() {
    this.body = _.get(this.specialJob, 'body');
  },
  methods: {
    ...mapActions({
      create: actions.SPECIALJOB_CREATE_ACTION,
    }),
    async onSubmit(e) {
      e.preventDefault();
      this.loading = true;
      try {
        await this.create({
          userId: this.authUserId,
          body: this.body,
          locationId: this.locationId,
        });
        this.$router.push("/main");
      } catch (error) {
        console.error("DEBUG: onSubmit", error)
      }
      this.loading = false;
    },
  },
  computed: {
  ...mapGetters({
      authUserId: getters.AUTH_USER_ID_GETTER,
      locationId: getters.DOMAIN_LOCATION_ID_GETTER,
      specialJob: getters.SPECIALJOB_LATEST_GETTER,
    }),
  }
}
</script>

